<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <h1>{{ `Manage Users` }}</h1>
            <div>
                <base-button type="white" size="sm" @click="openExportReportModal">Export</base-button>
                <base-button type="dark-blue" size="sm" @click="groupNotification.modal = true">Send Group
                    Message</base-button>
            </div>
        </div>
        <div class="mt-2 cardStyle p-3">
            <div class="d-flex flex-wrap filters">
                <base-input label="First Name" type="search" v-model="search.first_name" @change="filterSearch"
                    placeholder="First Name" class="filter-search mr-2"></base-input>
                <base-input label="Last Name" type="search" v-model="search.last_name" @change="filterSearch"
                    placeholder="Last Name" class="filter-search mr-2"></base-input>
                <base-input label="Email" type="search" v-model="search.email" @change="filterSearch" placeholder="Email"
                    class="filter-search mr-2"></base-input>
                <base-select label="Select Stage" v-model="search.stage" @change="filterSearch" :options="stagesOptions"
                    custom-clearable class="filter-search mr-2"></base-select>
                <base-select label="Status" v-model="search.status" @change="filterSearch" :options="activeOptions"
                    custom-clearable class="filter-search mr-2"></base-select>
            </div>
            <el-table class="mt-4 mb-0 table-responsive table-flush" header-row-class-name="thead-light" width="100%"
                :data="manageUsers" v-loading="loaders.fetching">
                <el-table-column label="ACTIONS" header-align="left" width="200">
                    <div slot-scope="{ $index, row }" class="d-flex">
                        <router-link :to="{ name: 'admin.manage-users.show', params: { id: row.id } }">
                            <img class="eye-icon mr-2" src="/img/eye.svg" alt="" />
                        </router-link>
                        <router-link :to="{ name: 'admin.manage-users.edit', params: { id: row.id } }">
                            <img src="/img/icons/buttons/edit.svg" class="mr-2" alt="" />
                        </router-link>
                        <img src="/img/icons/buttons/message.svg" class="mr-2 cursor-pointer" alt=""
                            @click="openPushNotificationModal(row.id)" />
                        <img src="/img/icons/buttons/delete.svg" class="cursor-pointer" alt=""
                            @click="deletePatient(row)" />
                    </div>
                </el-table-column>
                <el-table-column label="FIRST NAME" prop="first_name" header-align="center" align="center" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ scope.row.first_name }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Last Name" prop="last_name" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ scope.row.last_name }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="PROGRAM NAME" prop="current_program.name" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ scope.row.current_program ? scope.row.current_program.name : "N/A" }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="WEIGHT LOSS/GAIN" prop="weight" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            <i :class="['fas', getClassForWeight(scope.row.weight)]"></i>
                            {{ scope.row.weight > 0 ? scope.row.weight : (-1 * scope.row.weight) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="LOGGED FOOD" prop="food_logs0" width="150">
                    <template slot-scope="scope">
                        <div class="text-break" :class="[getClassForLoggedFood(scope.row.food_logs1)]">
                            {{ scope.row.food_logs0 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="STATUS" prop="status" width="150">
                    <template slot-scope="scope">
                        <div class="text-break" :class="[getClassForStatus(scope.row.status)]">
                            {{ scope.row.status ? 'Active' : 'Inactive' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="AVERAGE PROTEIN" prop="protien" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ scope.row.protien || 0 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="AVERAGE FIBER" prop="fiber" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ scope.row.fiber || 0 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="AVERAGE WATER" prop="water" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ scope.row.water || 0 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="CALORIE DIFFERENTIAL" prop="calorie_differential" width="150">
                    <template slot-scope="scope">
                        <div class="text-break"
                            :class="{ 'custom-red-manage-users': scope.row.calorie_differential < 0, 'text-success': scope.row.calorie_differential > 0 }">
                            {{ scope.row.calorie_differential || 0 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="CURRENT WEIGHT" prop="current_weight" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ scope.row.current_weight || 0 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="TARGET WEIGHT" prop="target_weight" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ scope.row.target_weight || 0 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="TARGET WEIGHT LOST" prop="total_weight_lost" width="200">
                    <template slot-scope="scope">
                        <div class="text-break"
                            :class="{ 'custom-red-manage-users': scope.row.total_weight_lost < 0, 'text-success': scope.row.total_weight_lost > 0 }">
                            {{ scope.row.total_weight_lost || 0 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="CURRENT STAGE" prop="current_stage" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ scope.row.current_stage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="SURGERY DATE" prop="surgery_date" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ formatDate(scope.row.surgery_date) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="LAST USED" prop="last_used" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ formatDate(scope.row.last_used) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="LAST PUSH" prop="last_push" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ formatDate(scope.row.last_push) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="JOIN DATE" prop="created" width="150">
                    <template slot-scope="scope">
                        <div class="text-break">
                            {{ formatDate(scope.row.created) }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="d-flex justify-content-end mt-4 mr-5">
                <custom-pagination class="pagination-no-border" v-model="pagination.current_page"
                    :per-page="pagination.per_page" :total="pagination.total" @input="changePage" />
            </div>
        </div>
        <div class="modals">
            <div v-if="$store.state.ManageUsersModule.sendGroupMessage"
                class="position-fixed d-flex bg-white send-group-message-div pr-5 pl-3 pt-3">
                <img alt="image" src="/img/upload.gif" class="send-group-message-image">
                <p class="send-group-message-heading">Sending Group Message. Leave this window open.<br>It may take a few
                    minutes.
                    <button class="send-group-message-cancel-button ml-2" @click="cancelSendGroupMessage">Cancel</button>
                </p>
            </div>
            <group-message-dialog :visible.sync="groupNotification.modal" @closeDialog="closeGroupMessageDialog" />
            <remove-modal :loader="loaders.removeModal" :modal-text="remove.modalText" :removeId="remove.id"
                @onRemove="removePatient" />
            <push-notification :notificationModal="notification.modal" @closeNotificationModal="closePushNotificationModal"
                :id="notification.id" />
                <el-dialog
                    title="Export Report"
                    :visible.sync="exportReport.modal"
                    width="30%"
                    :before-close="() => exportReport.modal = false">
                    <el-date-picker v-model="exportReport.date_range" type="daterange" align="right" unlink-panels range-separator="To"
                        start-placeholder="Start date" end-placeholder="End date" :picker-options="dateRangePickerOptions"  class="w-100 base-date-picker">
                    </el-date-picker>
                    <span slot="footer" class="dialog-footer">
                        <base-button type="secondary" @click="exportReport.modal = false">Cancel</base-button>
                        <base-button type="bari" @click="downloadPatientReport">Export</base-button>
                    </span>
                </el-dialog>
        </div>
    </div>
</template>
<script>
import PushNotification from "@/views/Components/Modals/PushNotification";
import BackButton from "@/components/Router/BackButton";
import { Table, TableColumn } from "element-ui";
import CustomPagination from "@/views/Components/Pagination/CustomPagination";
import RemoveModal from "@/components/Modals/RemoveModal.vue";
import GroupMessageDialog from "@/views/Components/SuperAdmin/ManageUsers/GroupMessageDialog.vue";
import authHelper from "@/util/authHelper";
import dateRangePickerMixin from "@/mixins/dateRangePickerMixin.js";


import moment from "moment";
export default {
    components: {
        BackButton,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        CustomPagination,
        RemoveModal,
        PushNotification,
        GroupMessageDialog
    },
    mixins: [dateRangePickerMixin],
    data() {
        return {
            loaders: {
                fetching: false,
                removeModal: false,
                exportReport: false,
            },
            search: {
                first_name: '',
                last_name: '',
                email: '',
                stage: '',
                status: '',
            },
            remove: {
                modalText: '',
                id: ''
            },
            notification: {
                modal: false,
                id: ''
            },
            groupNotification: {
                modal: false,
            },
            pagination: {
                current_page: 1,
                per_page: 10,
                total: 0
            },
            exportReport: {
                modal: false,
                date_range: [moment().startOf('month').toDate(), moment().toDate()]
            },
            activeOptions: [
                {
                    label: 'All Patients',
                    value: ''
                },
                {
                    label: 'Active',
                    value: 1
                },
                {
                    label: 'Inactive',
                    value: 0
                },
            ],
            stagesOptions: [
                {
                    label: 'All Stages',
                    value: '',
                },
                {
                    label: 'Considering Surgery',
                    value: 'Considering Surgery',
                },
                {
                    label: 'Pre-Op',
                    value: 'Pre-Op',
                },
                {
                    label: 'Post-Op',
                    value: 'Post-Op',
                },
                {
                    label: 'Non-Surgical',
                    value: 'Non-Surgical',
                },
            ],
            manageUsers: [],
        };
    },
    mounted() {
        this.search = { ...this.search, ...authHelper.getManageUsersSuperAdminSavedSearch() }
        this.getManageUsers();
    },
    methods: {
        /**
         *  Function is used to get classes for weight according to weight
         *  
         *  @param weight
         * 
         *  @return @string
         */
        getClassForWeight(weight) {
            if (weight > 0) return 'fa-arrow-up text-danger';
            else if (weight < 0) return 'fa-arrow-down text-success'
            else return ''
        },

        /**
         *  Function is used to get classes for logged  food
         *  
         *  @param log
         * 
         *  @return @string
         */
        getClassForLoggedFood(log) {
            return log == 'no' ? 'custom-red-manage-users text-success' : '';
        },

        /**
         *  Function is used to get classes for status accordingly
         *  
         *  @param status
         * 
         *  @return @string
         */
        getClassForStatus(status) {
            return status ? 'text-success' : 'text-danger';
        },

        /**
         *  Function is used to cancel api call of sending group message
         *  
         *  @return void
         */
        cancelSendGroupMessage() {
            this.$store.commit('ManageUsersModule/toggleSendGroupMessage', false);
            this.$store.dispatch('ManageUsersModule/_cancelGroupMessage')
        },

        /**
         *  Function is used to close dialog for sending group message
         *  
         *  @return void
         */
        closeGroupMessageDialog() {
            this.groupNotification.modal = false;
        },
        
        /**
         *  Function is used to open dialog for sending push notification to patient
         *  
         *  @param id
         *  
         *  @return void
         */
        openPushNotificationModal(id) {
            this.notification.modal = true;
            this.notification.id = id;
        },

        /**
         *  Function is used to close dialog for sending push notification to patient
         *  
         *  @param value
         *  
         *  @return void
         */
        closePushNotificationModal(value) {
            this.notification.modal = value;
            if (!value) this.notification.id = '';
        },

        /**
         *  Function is used to show dialog for deleting patient
         *  
         *  @param item
         * 
         *  @return void
         */
        deletePatient(item) {
            this.$store.commit("showRemoveModal");
            this.remove.modalText = 'You want to remove this User.';
            this.remove.id = item.id;
        },

        /**
         *  Function is used to remove patient from DB
         *  
         *  @return void
         */
        async removePatient() {
            this.loaders.fetching = true;
            try {
                let id = this.remove.id;
                let response = await this.$store.dispatch('ManageUsersModule/_deleteManageUsersById', { id });
                let {
                    data: { data, message },
                } = response;
                this.$notify.success({
                    title: 'Manage users',
                    message: message
                });
                this.$store.commit("hideRemoveModal");
                this.getManageUsers();

            } catch (error) {
                console.log(error);
                this.$notify.success({
                    title: 'Manage users',
                    message: error?.response?.data?.message || 'Something went wrong! Try again later.'
                });

            }
            this.loaders.fetching = false;
        },

        /**
         *  Function is used to change page of listing
         *  
         *  @return void
         */
        changePage() {
            this.getManageUsers();
        },

        /**
         *  Function is used to call listing api again with search params
         *  
         *  @return void
         */
        async filterSearch() {
            this.pagination.current_page = 1;
            authHelper.setManageUsersSuperAdminSavedSearch(this.search)
            await this.getManageUsers()
        },

        /**
         *  Function is used to format date in D-MMM-YYYY
         *  
         *  @param date
         * 
         *  @return @string
         */
        formatDate(date) {
            let formattedDate = moment(date);
            if (formattedDate.isValid()) return formattedDate.format("D-MMM-YYYY");
            return '';
        },

        /**
         *  Function is used to fetch patients listing
         *  
         *  @return void
         */
        async getManageUsers() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('ManageUsersModule/_getManageUsers', { params: { page: this.pagination.current_page, ...this.search } });
                let {
                    data: { data },
                } = response;
                this.pagination.current_page = data.current_page;
                this.pagination.per_page = data.per_page;
                this.pagination.total = data.total;
                this.manageUsers = data.data;
            } catch (error) {
                console.log(error);
                this.pagination.current_page = 1;
                this.pagination.per_page = 0;
                this.pagination.total = 0;
                this.manageUsers = [];

            }
            this.loaders.fetching = false;
        },

        openExportReportModal()
        {
            this.exportReport.modal = true;
        },

        /**
         *  Function is used to download report with search params
         *  
         *  @return void
         */
        async downloadPatientReport() {
            let vm = this;
            vm.exportReport.modal = false;
            vm.$store.commit('toggleDownloadReport', true);
            try {
                let response = await this.$store.dispatch('ManageUsersModule/_downloadPatientReport', { params: { ...this.search,date_range: vm.exportReport.date_range } });

                if (vm.$store.state.modals.downloadingReport) {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }))
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', ` ${moment().format('MMMM DD, YYYY')} patients.xlsx`)
                    document.body.appendChild(link);
                    link.click();
                    vm.exportPatients.includeName = false;
                }
            } catch (error) {

            }
            vm.$store.commit('toggleDownloadReport', false);

        },

    },
};
</script>
<style lang="scss">
.filters {
    .filter-search {
        width: 140px;

        .el-select,
        .form-control {
            height: calc(1.5em + 1.25rem + 2px);
        }
    }
}

.custom-red-manage-users {
    color: #fe6522;
}

.el-table {
    td {
        background-color: white;
    }
}

.send-group-message-heading {
    font: normal normal 600 14px/25px Open Sans;
    color: #000000;
}

.send-group-message-div {
    top: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0px 3px 30px #0000001A;
    border-radius: 6px;
    z-index: 1050;
}

.send-group-message-cancel-button {
    background: transparent;
    border: 0;
    font: normal normal 600 11px/15px Open Sans;
    color: #BB2255;
    padding-top: 6px;
    margin-right: -5px;
}

.send-group-message-image {
    width: 80px;
    height: 80px;
    margin-top: -15px;
    margin-right: -5px;
}
.base-date-picker {
    &.el-date-editor {
        .el-range-separator {
            width: 6% !important;
            font-size: 12px !important;
        }
    }
}
</style>
  