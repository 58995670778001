import moment from 'moment';

export default {
  data() {
    let shortcuts = [];
    for (let i = 0; i < 12; i++) {
        let startDate = moment().subtract(i, 'months').startOf('month');
        let endDate = moment().subtract(i, 'months').endOf('month');
        if (i == 0) {
            endDate = moment();
        }
        shortcuts = [...shortcuts, {
            text: startDate.format("MMM Y"),
            onClick(picker) {
              picker.$emit("pick", [startDate.toDate(), endDate.toDate()]);
            },
          },]
    }
    return {
      dateRangePickerOptions: {
        shortcuts: shortcuts,
      },
    };
  },
};
